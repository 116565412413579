import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/annonceManager.scss';

function AnnonceManager() {
  const [annonces, setAnnonces] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]); // Stocker les catégories
  const [subcategories, setSubcategories] = useState([]); // Stocker les sous-catégories
  const [newAnnonce, setNewAnnonce] = useState({
    title: '',
    description: '',
    price: '',
    detail: '',
    option: '',
    kit_content: '',
    image: null,
    additional_images: [],
    category_id: '',
    subcategory_id: ''
  });
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || !token) {
      setErrorMessage("Accès refusé. Vous devez être connecté pour accéder à cette page.");
      navigate('/login');
    } else {
      setUserRole(parsedUser.role);
      fetchAnnonces(token);
      fetchCategories(token);
    }
  }, [navigate]);
  const handleViewDetails = (id) => {
    // Rediriger l'utilisateur vers la page de détails de l'annonce
    navigate(`/annonceDetail/${id}`);
  };
  
  const fetchAnnonces = async (token) => {
    setLoading(true);
    try {
      // Si l'utilisateur est admin, on récupère toutes les annonces, sinon seulement les approuvées.
      const url = userRole === 'admin' 
        ? `https://www.npm06.com/api/annonces/?showAll=true` // Indique que toutes les annonces doivent être récupérées
        : `https://www.npm06.com/api/annonces/`; // Sinon, uniquement celles approuvées

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error('Erreur lors de la récupération des annonces');
      const data = await response.json();
      setAnnonces(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des annonces:', error);
      alert('Erreur de récupération des annonces. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async (token) => {
    try {
      const response = await fetch(`https://www.npm06.com/api/categories/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des catégories');
      const data = await response.json();
      setCategories(data); // Stocker les catégories récupérées
    } catch (error) {
      console.error('Erreur lors de la récupération des catégories:', error);
    }
  };

  const fetchSubcategories = async (categoryId, token) => {
    try {
      const response = await fetch(`https://www.npm06.com/api/categories/${categoryId}/subcategories`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Erreur lors de la récupération des sous-catégories');
      const data = await response.json();
      setSubcategories(data); // Stocker les sous-catégories
    } catch (error) {
      console.error('Erreur lors de la récupération des sous-catégories:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAnnonce((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'category_id') {
      const token = getToken();
      fetchSubcategories(value, token); // Met à jour les sous-catégories selon la catégorie choisie
    }
  };

  const handleFileChange = (e) => {
    setNewAnnonce((prevState) => ({ ...prevState, image: e.target.files[0] }));
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setNewAnnonce((prevState) => ({ ...prevState, additional_images: files }));
  };

  const handleAddNewAnnonce = async (e) => {
    e.preventDefault();

    if (isNaN(newAnnonce.price) || newAnnonce.price <= 0) {
      return alert('Veuillez entrer un prix valide.');
    }

    const token = getToken();
    const formData = new FormData();
    formData.append('title', newAnnonce.title);
    formData.append('description', newAnnonce.description);
    formData.append('price', newAnnonce.price);
    formData.append('detail', newAnnonce.detail || '');
    formData.append('option', newAnnonce.option || '');
    formData.append('kit_content', newAnnonce.kit_content || '');
    formData.append('category_id', newAnnonce.category_id || null);
    formData.append('subcategory_id', newAnnonce.subcategory_id || null);

    if (newAnnonce.image) {
      formData.append('image', newAnnonce.image);
    }

    newAnnonce.additional_images.forEach((file, index) => {
      formData.append(`additional_images_${index}`, file);
    });

    try {
      const response = await fetch(`https://www.npm06.com/api/annonces`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setMessage("Annonce ajoutée avec succès !");
        fetchAnnonces(token); // Rafraîchir la liste des annonces
        closeModal();
        openMessageModal();
      } else {
        setMessage("Une erreur est survenue lors de l'ajout.");
        openMessageModal();
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'annonce:', error);
      setMessage("Une erreur est survenue. Veuillez réessayer.");
      openMessageModal();
    }
  };

  const handleApproveAnnonce = async (id, isApproved) => {
    const token = getToken();
    try {
      const response = await fetch(`https://www.npm06.com/api/annonces/${id}/approve`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_approved: !isApproved }) // Inverse l'état d'approbation
      });

      if (response.ok) {
        fetchAnnonces(token); // Rafraîchir les annonces
        setMessage(`Annonce ${isApproved ? 'désapprouvée' : 'approuvée'} avec succès.`);
        openMessageModal();
      } else {
        throw new Error('Erreur lors de l\'approbation/désapprobation.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'approbation/désapprobation:', error);
      alert('Une erreur est survenue.');
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openMessageModal = () => {
    setIsMessageModalOpen(true);
  };

  const closeMessageModal = () => {
    setIsMessageModalOpen(false);
  };

  return (
    <div className="annonce-manager-container">
      <h1>Gestion des Annonces</h1>
      {errorMessage && <p>{errorMessage}</p>}
      {loading && <p>Chargement...</p>}

      <button className="add-annonce-button" onClick={openModal} style={{ display: 'block', margin: '20px auto' }}>
        Ajouter une annonce
      </button>

      <table className="table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Titre</th>
            <th>Description</th>
            <th>Prix</th>
            <th>Email Utilisateur</th>
            <th>Date de création</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {annonces.length > 0 ? (
    annonces.map((annonce) => (
      <tr key={annonce.id}>
        <td>
          <img
            src={annonce.image_url.startsWith('https') ? annonce.image_url : `https://npm06.com/uploads/${annonce.image_url}`}
            alt={annonce.title}
            style={{ width: '50px', height: '50px' }}
          />
        </td>
        <td>{annonce.title}</td>
        <td>{annonce.description}</td>
        <td>{annonce.price}</td>
        <td>{annonce.user_email}</td>
        <td>{new Date(annonce.created_at).toLocaleDateString()}</td>
        <td>
          <button onClick={() => handleViewDetails(annonce.id)}>Voir Détail</button>
          {userRole === 'admin' && (
            <button onClick={() => handleApproveAnnonce(annonce.id, annonce.is_approved)}>
              {annonce.is_approved ? 'Désapprouver' : 'Approuver'}
            </button>
          )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="7">Aucune annonce disponible</td>
    </tr>
  )}
</tbody>

      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <h2>Ajouter une annonce</h2>
            <form onSubmit={handleAddNewAnnonce}>
              <div>
                <label htmlFor="title">Titre</label>
                <input
                  type="text"
                  name="title"
                  value={newAnnonce.title}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="description">Description</label>
                <textarea
                  name="description"
                  value={newAnnonce.description}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <div>
                <label htmlFor="price">Prix</label>
                <input
                  type="number"
                  name="price"
                  value={newAnnonce.price}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="category">Catégorie</label>
                <select
                  name="category_id"
                  value={newAnnonce.category_id}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Sélectionner une catégorie</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="subcategory">Sous-catégorie</label>
                <select
                  name="subcategory_id"
                  value={newAnnonce.subcategory_id}
                  onChange={handleInputChange}
                >
                  <option value="">Sélectionner une sous-catégorie</option>
                  {subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label htmlFor="image">Image principale</label>
                <input type="file" name="image" onChange={handleFileChange} />
              </div>
              <div>
                <label htmlFor="additional_images">Images supplémentaires</label>
                <input
                  type="file"
                  name="additional_images"
                  onChange={handleAdditionalImagesChange}
                  multiple
                />
              </div>
              <button type="submit">Ajouter</button>
            </form>
          </div>
        </div>
      )}

      {isMessageModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeMessageModal}>&times;</span>
            <h2>{message}</h2>
            <button onClick={closeMessageModal}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AnnonceManager;
