import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/AppContext';
// import config2 from '../config/config2'; // Assurez-vous de créer un fichier config2.js
import { Link, useNavigate } from 'react-router-dom';
import '../scss/Annonces.scss';

const Annonces = () => {
  const { annonces, fetchAnnonces, addAnnonce, user, addToCart } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false); // État pour l'alerte
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    image_url: 'no-pict.jpg',
    additional_images: [],
    detail: '',
    option: '',
    kit_content: '',
  });
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate(); // Hook pour naviguer entre les pages

  // Récupérer les annonces lorsque le composant est monté
  useEffect(() => {
    fetchAnnonces(); // Récupération des annonces à partir du contexte
  }, [fetchAnnonces]);

  const handleAddAnnonceClick = () => {
    const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');

    if (!token || !user || (user.role !== 'user' && user.role !== 'admin')) {
      alert('Vous devez être connecté pour ajouter une annonce.');
      navigate('/login');
    } else {
      setIsModalOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({ ...prevState, image_url: file }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleCancelImage = () => {
    setFormData((prevState) => ({ ...prevState, image_url: 'no-pict.jpg' }));
    setPreviewImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.description || !formData.price) {
      alert('Tous les champs sont obligatoires.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('title', formData.title);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('detail', formData.detail);
    formDataToSend.append('option', formData.option);
    formDataToSend.append('kit_content', formData.kit_content);
    formDataToSend.append('is_approved', false);

    if (formData.image_url && formData.image_url instanceof File) {
      formDataToSend.append('image', formData.image_url);
    }

    formData.additional_images.forEach((image) => {
      if (image instanceof File) {
        formDataToSend.append('additional_images', image);
      }
    });

    try {
      const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
      if (!token) {
        alert('Vous devez être connecté pour ajouter une annonce.');
        return;
      }

      await addAnnonce(formDataToSend, token);
      setIsModalOpen(false);
      setFormData({
        title: '',
        description: '',
        price: '',
        image_url: 'no-pict.jpg',
        additional_images: [],
        detail: '',
        option: '',
        kit_content: '',
      });
      setPreviewImage(null);
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'annonce:", error);
      alert("Erreur lors de l'ajout de l'annonce");
    }
  };

  // Fonction pour ajouter une annonce au panier et afficher une alerte
  const handleAddToCart = (annonce) => {
    addToCart(annonce);
    setIsAlertOpen(true); // Ouvrir l'alerte
  };

  // Fonction pour naviguer vers la page du panier
  const handleGoToCart = () => {
    setIsAlertOpen(false);
    navigate('/cart'); // Redirige vers la page du panier
  };

  return (
    <div className="annonces-section">
      <h2>Nos Annonces</h2>
      <button className="add-annonce-button" onClick={handleAddAnnonceClick}>
        Ajouter une Annonce
      </button>

      {/* Modal pour ajouter une annonce */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Titre de l'annonce"
                required
              />
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Description"
                required
              ></textarea>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Prix"
                required
              />
              <textarea
                name="detail"
                value={formData.detail}
                onChange={handleInputChange}
                placeholder="Détails supplémentaires"
              ></textarea>
              <textarea
                name="option"
                value={formData.option}
                onChange={handleInputChange}
                placeholder="Options"
              ></textarea>
              <textarea
                name="kit_content"
                value={formData.kit_content}
                onChange={handleInputChange}
                placeholder="Contenu du kit"
              ></textarea>

              <div className="image-upload-section">
                <input type="file" name="image" onChange={handleFileChange} />
                {previewImage && (
                  <div className="image-preview">
                    <img src={previewImage} alt="Prévisualisation" style={{ width: '100px', height: '100px' }} />
                    <button type="button" onClick={handleCancelImage}>Annuler l'image</button>
                  </div>
                )}
              </div>

              <button type="submit">Ajouter l'Annonce</button>
            </form>
          </div>
        </div>
      )}

      {/* Alerte de confirmation pour l'ajout au panier */}
      {isAlertOpen && (
        <div className="alert-box">
          <div className="alert-content">
            <p>Annonce ajoutée au panier avec succès !</p>
            <button onClick={() => setIsAlertOpen(false)}>Continuer les achats</button>
            <button onClick={handleGoToCart}>Voir le Panier</button> {/* Nouveau bouton pour aller au panier */}
          </div>
        </div>
      )}

      <div className="annonces-grid">
        {Array.isArray(annonces) && annonces.length > 0 ? (
          annonces.map((annonce) => (
            <div key={annonce.id} className="annonce-card">
             <img
 src={`https://npm06.com/uploads/${annonce.image_url}`}

  alt={annonce.title}
  style={{ width: '150px', height: '150px' }}
/>
              <h3>{annonce.title}</h3>
              <p>{annonce.description}</p>
              <p>{annonce.price} €</p>
              <Link to={`/annonceDetail/${annonce.id}`} className="detail-button">
                Voir Détail
              </Link>
              <button className="add-to-cart-button" onClick={() => handleAddToCart(annonce)}>
                Ajouter au Panier
              </button>
            </div>
          ))
        ) : (
          <p>Aucune annonce disponible pour le moment.</p>
        )}
      </div>
    </div>
  );
};

export default Annonces;
