import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useState, useCallback, useEffect, useContext } from 'react';
import { AppContext } from './context/AppContext';
import PropTypes from 'prop-types';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import ServiceOverview from './components/ServiceOverview';
import Signup from './components/Signup';
import Login from './components/Login';
import Profile from './components/Profile';
import MyOrders from './components/MyOrders';
import MonCompte from './components/MonCompte';
import Contact from './components/Contact';
import CartSummary from './components/CartSummary';
import Shopping from './components/Shopping';
import OrderPage from './components/OrderPage';
import Annonces from './components/Annonces';
import AnnonceDetail from './components/AnnoncesDetail';
import ProductDetail from './components/ProductDetail';
import ConditionsGeneralesVente from './components/ConditionsGeneralesVente';
import WebmasterFullStack from './components/WebmasterFullStack';
import CustomSongService from './components/CustomSongService';
import VideoEditing from './components/VideoEditing';
import DashboardLayout from './admin/composants/DashboardLayout';
import ProductManager from './admin/composants/ProductManager';
import SliderManager from './admin/composants/SliderManager';
import AnnoncesManager from './admin/composants/AnnoncesManager';
import UserManager from './admin/composants/UserManager';
import YouTubeManager from './admin/composants/YouTubeManager';
import MessagesManager from './admin/composants/MessagesManager';
import AdminProtectedRoute from './components/AdminProtectedRoute';
import SliderComponent from './components/SliderComponent';
import PrivacyPolicyPopup from './components/PrivacyPolicyPopup';
import PaymentPage from './components/PaymentPage';
import PaymentSuccess from './components/PaymentSuccess';
import YouTubeDetail from './components/YouTubeDetail';
import YouTubeVideos from './components/YouTubeVideos';
import ConfirmCommande from './components/ConfirmCommande';
import MessageUser from './components/MessageUser';
import Chatbot from './components/Chatbot';
import NotFoundPage from './components/NotFoundPage';

import './App.css';

// Hook pour gérer l'authentification
const useAuth = () => {
  const { user } = useContext(AppContext);
  const isAdmin = user?.role === 'admin';
  return { user, isAdmin };
};

// Composant pour les routes protégées (non admin)
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

// Composant principal
const App = () => {
  const { user, setUser } = useContext(AppContext);

  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [isPrivacyPopupOpen, setPrivacyPopupOpen] = useState(false);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  // Gestion de la popup des politiques de confidentialité
  useEffect(() => {
    const privacyAccepted = localStorage.getItem('privacyAccepted');
    if (!privacyAccepted) {
      setPrivacyPopupOpen(true);
    }
  }, []);

  const handlePrivacyAccept = () => {
    localStorage.setItem('privacyAccepted', 'true');
    setPrivacyPopupOpen(false);
  };

  const handleLoginSuccess = (userData) => {
    const storage = userData.rememberMe ? localStorage : sessionStorage;
    storage.setItem('user', JSON.stringify(userData));
    setUser(userData); // Mettre à jour l'utilisateur dans le contexte
  };

  const addToCart = useCallback(
    (product) => {
      const existingProduct = cart.find((item) => item.id === product.id);
      let updatedCart;
      if (existingProduct) {
        updatedCart = cart.map((item) =>
          item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        updatedCart = [...cart, { ...product, quantity: 1 }];
      }
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      setCart(updatedCart);
    },
    [cart]
  );

  return (
    <Router>
      <Header user={user} setUser={setUser} />
      <SliderComponent />

      <Routes>
        {/* Routes accessibles à tous */}
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/login"
          element={
            <Login
              isOpen={isLoginModalOpen}
              onClose={() => setLoginModalOpen(false)}
              onLoginSuccess={handleLoginSuccess}
            />
          }
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ServiceOverview" element={<ServiceOverview />} />
        <Route path="/cart" element={<CartSummary />} />
        <Route path="/shopping" element={<Shopping addToCart={addToCart} />} />
        <Route path="/orderpage" element={<OrderPage />} />
        <Route path="/annonce" element={<Annonces />} />
        <Route path="/annoncedetail/:id" element={<AnnonceDetail />} />
        <Route path="/productdetail/:id" element={<ProductDetail addToCart={addToCart} />} />
        <Route path="/conditions-generales-vente" element={<ConditionsGeneralesVente />} />
        <Route path="/webmaster-fullstack" element={<WebmasterFullStack />} />
        <Route path="/customsongservice" element={<CustomSongService />} />
        <Route path="/videoediting" element={<VideoEditing />} />
        <Route path="/YouTubeVideos" element={<YouTubeVideos />} />
        <Route path="/youtubedetail/:youtubeId" element={<YouTubeDetail />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/chatbot" element={<Chatbot />} />

        {/* Routes protégées pour les utilisateurs connectés */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile user={user} setUser={setUser} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/MyOrders"
          element={
            <ProtectedRoute>
              <MyOrders user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirmcommande"
          element={
            <ProtectedRoute>
              <ConfirmCommande user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/messageuser"
          element={
            <ProtectedRoute>
              <MessageUser user={user} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/MonCompte"
          element={
            <ProtectedRoute>
              <MonCompte user={user} setUser={setUser} />
            </ProtectedRoute>
          }
        />

        {/* Routes Admin protégées */}
        <Route
          path="/admin/dashboardlayout"
          element={
            <AdminProtectedRoute user={user}>
              <DashboardLayout />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/productmanager"
          element={
            <AdminProtectedRoute>
              <ProductManager />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/slider-manager"
          element={
            <AdminProtectedRoute>
              <SliderManager />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/messagesmanager"
          element={
            <AdminProtectedRoute>
              <MessagesManager />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/usermanager"
          element={
            <AdminProtectedRoute>
              <UserManager />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/annoncesmanager"
          element={
            <AdminProtectedRoute>
              <AnnoncesManager />
            </AdminProtectedRoute>
          }
        />
        <Route
          path="/admin/youtubemanager"
          element={
            <AdminProtectedRoute>
              <YouTubeManager />
            </AdminProtectedRoute>
          }
        />

        {/* Page non trouvée */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      {/* <Chatbot /> */}
      <Footer />
      
      {/* Pop-up des politiques de confidentialité */}
      {isPrivacyPopupOpen && (
        <PrivacyPolicyPopup isOpen={isPrivacyPopupOpen} onClose={handlePrivacyAccept} />
      )}
    </Router>
  );
};

export default App;
