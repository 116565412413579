import { useEffect, useState, useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import '../scss/Shopping.scss';

const Shopping = () => {
  const { products, fetchProducts, addProduct, user, addToCart } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false); // État pour l'alerte
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    image_url: 'no-pict.jpg',
    additional_images: [],
    detail: '',
    option: '',
    kit_content: '',
  });
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate(); // Hook pour naviguer entre les pages

  // Récupérer les produits lorsque le composant est monté
  useEffect(() => {
    fetchProducts(); // Récupération des produits à partir du contexte
  }, [fetchProducts]);

  const handleAddProductClick = () => {
    const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');

    if (!token || !user || (user.role !== 'user' && user.role !== 'admin')) {
      alert('Vous devez être connecté pour ajouter un produit.');
      navigate('/login');
    } else {
      setIsModalOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({ ...prevState, image_url: file }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleCancelImage = () => {
    setFormData((prevState) => ({ ...prevState, image_url: 'no-pict.jpg' }));
    setPreviewImage(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.description || !formData.price) {
      alert('Tous les champs sont obligatoires.');
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append('name', formData.name);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('price', formData.price);
    formDataToSend.append('detail', formData.detail);
    formDataToSend.append('option', formData.option);
    formDataToSend.append('kit_content', formData.kit_content);

    if (formData.image_url && formData.image_url instanceof File) {
      formDataToSend.append('image', formData.image_url);
    }

    formData.additional_images.forEach((image) => {
      if (image instanceof File) {
        formDataToSend.append('additional_images', image);
      }
    });

    try {
      const token = localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
      if (!token) {
        alert('Vous devez être connecté pour ajouter un produit.');
        return;
      }

      await addProduct(formDataToSend, token);
      setIsModalOpen(false);
      setFormData({
        name: '',
        description: '',
        price: '',
        image_url: 'no-pict.jpg',
        additional_images: [],
        detail: '',
        option: '',
        kit_content: '',
      });
      setPreviewImage(null);
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit:", error);
      alert("Erreur lors de l'ajout du produit");
    }
  };

  // Fonction pour ajouter un produit au panier et afficher une alerte
  const handleAddToCart = (product) => {
    addToCart(product);
    setIsAlertOpen(true); // Ouvrir l'alerte
  };

  // Fonction pour naviguer vers la page du panier
  const handleGoToCart = () => {
    setIsAlertOpen(false);
    navigate('/cart'); // Redirige vers la page du panier
  };

  return (
    <div className="shopping-section">
      <h2>Nos Produits</h2>
      <button className="add-product-button" onClick={handleAddProductClick}>
        Ajouter un Produit
      </button>

      {/* Modal pour ajouter un produit */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setIsModalOpen(false)}>&times;</span>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Nom du produit"
                required
              />
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Description"
                required
              ></textarea>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Prix"
                required
              />
              <textarea
                name="detail"
                value={formData.detail}
                onChange={handleInputChange}
                placeholder="Détails supplémentaires"
              ></textarea>
              <textarea
                name="option"
                value={formData.option}
                onChange={handleInputChange}
                placeholder="Options"
              ></textarea>
              <textarea
                name="kit_content"
                value={formData.kit_content}
                onChange={handleInputChange}
                placeholder="Contenu du kit"
              ></textarea>

              <div className="image-upload-section">
                <input type="file" name="image" onChange={handleFileChange} />
                {previewImage && (
                  <div className="image-preview">
                    <img src={previewImage} alt="Prévisualisation" style={{ width: '100px', height: '100px' }} />
                    <button type="button" onClick={handleCancelImage}>Annuler l'image</button>
                  </div>
                )}
              </div>

              <button type="submit">Ajouter le Produit</button>
            </form>
          </div>
        </div>
      )}

      {/* Alerte de confirmation pour l'ajout au panier */}
      {isAlertOpen && (
        <div className="alert-box">
          <div className="alert-content">
            <p>Produit ajouté au panier avec succès !</p>
            <button onClick={() => setIsAlertOpen(false)}>Continuer les achats</button>
            <button onClick={handleGoToCart}>Voir le Panier</button> {/* Nouveau bouton pour aller au panier */}
          </div>
        </div>
      )}

      <div className="products-grid">
        {Array.isArray(products) && products.length > 0 ? (
          products.map((product) => (
            <div key={product.id} className="product-card">
              <img
                src={`https://npm06.com/uploads/${product.image_url}`} // URL correcte
                alt={product.name}
                style={{ width: '150px', height: '150px' }}
              />
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <p>{product.price} €</p>
              <Link to={`/productDetail/${product.id}`} className="detail-button">   
                Voir Détail
              </Link>
              <button className="add-to-cart-button" onClick={() => handleAddToCart(product)}>
                Ajouter au Panier
              </button>
            </div>
          ))
        ) : (
          <p>Aucun produit disponible pour le moment.</p>
        )}
      </div>
    </div>
  );
};

export default Shopping;
