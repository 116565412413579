import '../scss/WebmasterFullStack.scss'; // Assurez-vous d'avoir un fichier SCSS correspondant

const WebmasterFullStack = () => {
  return (
    <div className="webmaster-fullstack">
      {/* Section d'en-tête */}
      <header className="header-section">
        <div className="header-content">
          <h1>Développeur Full Stack</h1>
          <p>Conception, développement et optimisation de sites web performants et modernes.</p>
        </div>
      </header>

      {/* Section des services */}
      <section className="services-section">
        <h2>Services</h2>
        <div className="services-grid">
          <div className="service-card">
            <h3>Développement Front-End</h3>
            <p>Création d'interfaces utilisateur modernes et réactives.</p>
          </div>
          <div className="service-card">
            <h3>Développement Back-End</h3>
            <p>Gestion des bases de données, API, et logiques serveur.</p>
          </div>
          <div className="service-card">
            <h3>SEO & Performance</h3>
            <p>Optimisation du référencement naturel et de la vitesse de chargement.</p>
          </div>
        </div>
      </section>

      {/* Section des projets */}
      <section className="projects-section">
        <h2>Projets Réalisés</h2>
        <div className="projects-grid">
          <div className="project-card">
            <h3>Projet 1</h3>
            <p>Description du projet 1...</p>
          </div>
          <div className="project-card">
            <h3>Projet 2</h3>
            <p>Description du projet 2...</p>
          </div>
          <div className="project-card">
            <h3>Projet 3</h3>
            <p>Description du projet 3...</p>
          </div>
        </div>
      </section>

      {/* Section de contact */}
      <section className="contact-section">
        <h2>Contact</h2>
        <form className="contact-form">
          <input type="text" placeholder="Votre nom" required />
          <input type="email" placeholder="Votre email" required />
          <textarea placeholder="Votre message" required></textarea>
          <button type="submit">Envoyer</button>
        </form>
      </section>

      {/* Footer */}
      <footer className="footer-section">
        <p>&copy; 2024 NPM RUN DEV. Tous droits réservés.</p>
      </footer>
    </div>
  );
};

export default WebmasterFullStack;
