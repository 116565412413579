import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

const AdminProtectedRoute = ({ children, user }) => {
  const [loading, setLoading] = useState(true);
  const isAuthenticated = !!user;
  const isAdmin = user?.role === 'admin';

  useEffect(() => {
    if (user !== undefined) {
      setLoading(false); // Quand 'user' est disponible, arrête le chargement
    }
  }, [user]);

  if (loading) {
    return <p>Chargement...</p>; // Affiche un état de chargement
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />; // Redirige vers la page de login si non authentifié
  }

  if (!isAdmin) {
    return <Navigate to="/access-denied" />; // Redirige vers accès refusé si non admin
  }

  return children; // Affiche le contenu protégé si l'utilisateur est admin
};

AdminProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
};

export default AdminProtectedRoute;
