import { useState, useContext, useEffect } from 'react';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import '../scss/CustomSongService.scss';
import musicvideo from '../assets/fond_music.mp4';

const CustomSongService = () => {
  const { addToCart, isLoggedIn } = useContext(AppContext);
  const [songTitle, setSongTitle] = useState('');
  const [musicStyle, setMusicStyle] = useState('');
  const [customText, setCustomText] = useState('');
  const [songWish, setSongWish] = useState('');
  const [isCustomText, setIsCustomText] = useState(false);
  const [includeClip, setIncludeClip] = useState(false);
  const [clipOption, setClipOption] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [price, setPrice] = useState(10); // Prix de base : 10€
  const [isFormValid, setIsFormValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const navigate = useNavigate();

  // Tarifs mis à jour pour les options
  const basePrice = 10;
  const clipSurprisePrice = 20;
  const clipCustomPrice = 30;

  // Mise à jour du prix en fonction des choix
  useEffect(() => {
    let calculatedPrice = basePrice;
    if (includeClip) {
      calculatedPrice += clipOption === 'Surprise' ? clipSurprisePrice : clipCustomPrice;
    }
    setPrice(calculatedPrice);
  }, [includeClip, clipOption]);

  // Validation dynamique du formulaire
  useEffect(() => {
    const isValid = songTitle && musicStyle && (customText || songWish) && (!includeClip || (includeClip && (clipOption !== 'Personnalisé' || uploadedFiles.length > 0)));
    setIsFormValid(isValid);
  }, [songTitle, musicStyle, customText, songWish, includeClip, clipOption, uploadedFiles]);

  // Ajouter la chanson personnalisée au panier
  const handleAddToCart = (e) => {
    e.preventDefault();

    const newSong = {
      song_id: null, // Le champ song_id sera généré par la base de données
      user_id: null, // Le user_id sera récupéré après la connexion de l'utilisateur
      title: songTitle,
      music_style: musicStyle,
      custom_text: customText || null,
      song_wish: songWish || null,
      include_clip: includeClip ? 1 : 0,
      clip_option: clipOption || null,
      price,
      created_at: new Date(),
      updated_at: new Date(),
      uploaded_files: uploadedFiles.length ? JSON.stringify(uploadedFiles) : null, // Conserver les fichiers sous forme de tableau JSON
    };

    addToCart(newSong); // Ajouter au panier
    setIsModalOpen(false); // Fermer la modale après ajout
    handleResetFields(); // Réinitialiser le formulaire après ajout
  };

  // Ouvrir la modale si l'utilisateur est connecté
  const handleOpenModal = () => {
    if (isLoggedIn) {
      setIsModalOpen(true);
    } else {
      navigate('/login'); // Redirection vers la page de connexion si non connecté
    }
  };

  // Réinitialiser tous les champs du formulaire
  const handleResetFields = () => {
    setSongTitle('');
    setMusicStyle('');
    setCustomText('');
    setSongWish('');
    setIsCustomText(false);
    setIncludeClip(false);
    setClipOption('');
    setUploadedFiles([]);
  };

  // Ouvrir le modal d'upload
  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  // Fermer le modal d'upload
  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  // Gestion de l'upload de fichier
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      setUploadedFiles((prevFiles) => [...prevFiles, file]);
      handleCloseUploadModal(); // Fermer le modal après upload
    } else {
      alert('Le fichier est trop volumineux. La taille maximale est de 5 Mo.');
    }
  };

  return (
    <section className="custom-song-service">
      <video autoPlay loop muted id="video-background">
        <source src={musicvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="content-wrapper">
        {/* Texte accrocheur à gauche */}
        <div className="catchy-text-box">
          <h2>🎵 Créez une Chanson Personnalisée Inoubliable ! 🎤</h2>
          <p>
            Imaginez une chanson qui capture parfaitement vos émotions, vos idées, ou même un moment spécial de votre vie. Que ce soit pour une occasion unique, un événement familial, ou un simple cadeau original, nous sommes là pour transformer vos idées en mélodie.
          </p>
          <p>
            Vous avez le choix de personnaliser chaque détail :
            <br /> - Fournissez-nous un <strong>texte personnalisé</strong> ou laissez-nous la liberté de créer un texte qui vous surprendra.
            <br /> - Vous pouvez même <strong>ajouter un clip vidéo</strong> à votre chanson, que ce soit un <strong>clip surprise</strong> ou un <strong>clip personnalisé</strong>.
          </p>
          <p>
            <strong>Nos Tarifs :</strong>
            <br /> - Chanson de base (personnalisée ou non) : 10€
            <br /> - Ajout d'un clip surprise : +20€
            <br /> - Ajout d'un clip personnalisé : +30€
          </p>
          <p>
            Créez une œuvre d'art sonore qui restera gravée dans vos souvenirs et ceux de vos proches. Vous êtes prêt(e) ? Cliquez ci-dessous pour commencer votre projet musical !
          </p>
        </div>

        {/* Tarifs à droite */}
        <div className="pricing-info">
          <h3>Nos Tarifs</h3>
          <ul>
            <li>Chanson de base (personnalisée ou non) : {basePrice}€</li>
            <li>Clip surprise : +{clipSurprisePrice}€</li>
            <li>Clip personnalisé : +{clipCustomPrice}€</li>
          </ul>

          {/* Bouton pour ouvrir le modal */}
          <button onClick={handleOpenModal} className="btn-primary">
            Créer une chanson personnalisée
          </button>
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} modalStyle={{ width: '800px', height: '600px', background: 'rgba(255, 255, 255, 0.9)', padding: '20px' }}>
          <h3>Personnaliser Votre Chanson</h3>
          <form>
            {/* Titre de la chanson */}
            <div className="form-group">
              <input
                type="text"
                value={songTitle}
                onChange={(e) => setSongTitle(e.target.value)}
                placeholder="Titre de la chanson"
              />
            </div>

            {/* Style musical */}
            <div className="form-group">
              <select value={musicStyle} onChange={(e) => setMusicStyle(e.target.value)}>
                <option value="">Sélectionnez un style musical</option>
                <option value="Pop">Pop</option>
                <option value="Rock">Rock</option>
                <option value="Jazz">Jazz</option>
                <option value="Classique">Classique</option>
                <option value="Electro">Electro</option>
                <option value="Hip-hop">Hip-hop</option>
              </select>
            </div>

            {/* Choix : texte personnalisé ou généré */}
            <div className="form-group">
              <label>Souhaitez-vous fournir un texte personnalisé ou laisser l'application générer le texte ?</label>
              <div>
                <input
                  type="radio"
                  id="customText"
                  name="textOption"
                  value="custom"
                  checked={isCustomText}
                  onChange={() => setIsCustomText(true)}
                />
                <label htmlFor="customText">Texte personnalisé</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="generatedText"
                  name="textOption"
                  value="generated"
                  checked={!isCustomText}
                  onChange={() => setIsCustomText(false)}
                />
                <label htmlFor="generatedText">Texte généré par nous</label>
              </div>
            </div>

            {/* Champ de texte personnalisé */}
            {isCustomText && (
              <div className="form-group">
                <textarea
                  value={customText}
                  onChange={(e) => setCustomText(e.target.value)}
                  placeholder="Texte personnalisé"
                  style={{ height: '150px' }} // Augmentation de la hauteur de la zone de texte
                />
              </div>
            )}

            {/* Souhait pour la chanson */}
            {!isCustomText && (
              <div className="form-group">
                <textarea
                  value={songWish}
                  onChange={(e) => setSongWish(e.target.value)}
                  placeholder="Souhait pour la chanson"
                  style={{ height: '150px' }} // Augmentation de la hauteur de la zone de texte
                />
              </div>
            )}

            {/* Option pour inclure un clip vidéo */}
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={includeClip}
                  onChange={(e) => setIncludeClip(e.target.checked)}
                />
                Ajouter un clip vidéo ?
              </label>
            </div>

            {/* Si clip vidéo sélectionné, choisir l'option */}
            {includeClip && (
              <div className="form-group">
                <select value={clipOption} onChange={(e) => setClipOption(e.target.value)}>
                  <option value="">Sélectionnez une option de clip</option>
                  <option value="Surprise">Clip surprise (+{clipSurprisePrice}€)</option>
                  <option value="Personnalisé">Clip personnalisé (+{clipCustomPrice}€)</option>
                </select>
              </div>
            )}

            {/* Lien pour uploader un fichier si "Clip Personnalisé" est choisi */}
            {clipOption === 'Personnalisé' && (
              <div className="form-group">
                <p>
                  <a href="#!" onClick={handleOpenUploadModal}>
                    Cliquez ici pour uploader votre fichier (5 Mo max)
                  </a>
                </p>
                {/* Afficher les fichiers uploadés */}
                {uploadedFiles.length > 0 && (
                  <ul>
                    {uploadedFiles.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {/* Prix total */}
            <div className="form-group">
              <p>Prix total : {price}€</p>
            </div>

            {/* Bouton d'ajout au panier */}
            <div className="form-group">
              <button
                type="submit"
                className="btn-primary"
                disabled={!isFormValid}
                onClick={handleAddToCart}
              >
                Ajouter au Panier
              </button>
              <button type="button" className="btn-secondary" onClick={handleResetFields}>
                Effacer tous les champs
              </button>
              <button type="button" className="btn-secondary" onClick={() => setIsModalOpen(false)}>
                Annuler
              </button>
            </div>
          </form>
        </Modal>
      )}

      {/* Modal pour uploader le fichier */}
      {isUploadModalOpen && (
        <Modal isOpen={isUploadModalOpen} onClose={handleCloseUploadModal}>
          <h3>Uploader votre fichier</h3>
          <input type="file" onChange={handleFileUpload} />
          <p>Taille maximale : 5 Mo</p>
        </Modal>
      )}
    </section>
  );
};

export default CustomSongService;
