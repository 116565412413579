import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../AdminScss/adminCommande.scss';
import config from '../../config/config';
function AdminCommande() {
  const [commandes, setCommandes] = useState([]);
  const [selectedCommande, setSelectedCommande] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Pour ouvrir et fermer le modal d'édition
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false); // Pour ouvrir et fermer le modal de détails
  const [formData, setFormData] = useState({
    order_id: '',
    user_name: '',
    items: [],
    status: 'pending',
    is_paid: false,
    total_price: '',
    delivery_price: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Fonction pour récupérer le token
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Vérification si l'utilisateur est admin et récupération des commandes
  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchCommandes(token);
    }
  }, [navigate]);

  const fetchCommandes = async (token) => {
    try {
      const response = await fetch('https://www.npm06.com/api/orders/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des commandes');
      }
      const data = await response.json();
      setCommandes(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes:', error);
    }
  };

  // Ouvre le modal pour modifier la commande
  const openModal = (commande) => {
    setSelectedCommande(commande);
    setFormData({
      order_id: commande?.order_id || '',
      user_name: commande?.user_name || '',
      items: commande?.items || [],
      status: commande?.status || 'pending',
      is_paid: commande?.is_paid || false,
      total_price: commande?.total_price || '',
      delivery_price: commande?.delivery_price || '',
    });
    setIsModalOpen(true);
  };

  // Ouvre le modal pour voir les détails de la commande
  const openDetailModal = (commande) => {
    setSelectedCommande(commande);
    setIsDetailModalOpen(true);
  };

  // Ferme le modal d'édition
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCommande(null);
  };

  // Ferme le modal de détails
  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
    setSelectedCommande(null);
  };

  // Gère les changements de valeurs dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Sauvegarde les modifications de la commande
  const handleSave = async (e) => {
    e.preventDefault();
    const token = getToken();
    const url = selectedCommande
      ? `https://www.npm06.com/api/orders/${selectedCommande.order_id}`
      : `https://www.npm06.com/api/orders/`;

    const method = selectedCommande ? 'PUT' : 'POST'; // Utilise PUT pour les modifications

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        fetchCommandes(token); // Met à jour la liste des commandes après modification
        closeModal(); // Ferme le modal après sauvegarde
      } else {
        const errorMessage = await response.text();
        console.error('Erreur lors de l\'enregistrement de la commande:', errorMessage);
        alert('Une erreur est survenue lors de l\'enregistrement. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la commande:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
};

  // Supprime une commande
  const handleDeleteCommande = async (id) => {
    const confirmation = window.confirm('Êtes-vous sûr de vouloir supprimer cette commande ?');
    if (!confirmation) return;

    try {
      const token = getToken();
      const response = await fetch(`${config}/api/orders/${id}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.ok) {
        fetchCommandes(token); // Met à jour la liste des commandes après suppression
      } else {
        console.error('Erreur lors de la suppression de la commande:', response.statusText);
        alert('Une erreur est survenue lors de la suppression.');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la commande:', error);
      alert('Une erreur est survenue. Veuillez vérifier votre connexion et réessayer.');
    }
  };

  return (
    <div className="admin-commande-container">
      <h1>Gestion des Commandes</h1>
      {errorMessage && <p>{errorMessage}</p>}

      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Utilisateur</th>
            <th>Date de Création</th>
            <th>Date d'Expédition</th>
            <th>Total</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {commandes.length > 0 ? (
            commandes.map((commande) => (
              <tr key={commande.order_id}>
                <td>{commande.order_id}</td>
                <td>{commande.user_name}</td>
                <td>{new Date(commande.createdAt).toLocaleDateString()}</td>
                <td>{commande.invoice_date ? new Date(commande.invoice_date).toLocaleDateString() : 'En préparation'}</td>
                <td>{commande.total_price} €</td>
                <td>
                  <button onClick={() => openDetailModal(commande)}>Détail</button>
                  <button onClick={() => openModal(commande)}>Modifier</button>
                  <button onClick={() => handleDeleteCommande(commande.order_id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Aucune commande disponible</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal pour modifier la commande */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>
              &times;
            </span>

            <h2>Modifier la commande {formData.order_id}</h2>

            <form onSubmit={handleSave}>
              <div className="form-group">
                <label htmlFor="user_name">Nom de l'utilisateur</label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  value={formData.user_name}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="total_price">Prix total</label>
                <input
                  type="number"
                  id="total_price"
                  name="total_price"
                  value={formData.total_price}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="status">Statut</label>
                <select
                  id="status"
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                >
                  <option value="pending">En attente</option>
                  <option value="shipped">Expédiée</option>
                  <option value="delivered">Livrée</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="is_paid">Paiement effectué</label>
                <input
                  type="checkbox"
                  id="is_paid"
                  name="is_paid"
                  checked={formData.is_paid}
                  onChange={() => setFormData((prev) => ({ ...prev, is_paid: !prev.is_paid }))}
                />
              </div>

              <button type="submit">Mettre à jour</button>
            </form>
          </div>
        </div>
      )}

      {/* Modal pour les détails de la commande */}
      {isDetailModalOpen && selectedCommande && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeDetailModal}>
              &times;
            </span>
            <h2>Détails de la commande {selectedCommande.order_id}</h2>
            <p><strong>Utilisateur :</strong> {selectedCommande.user_name}</p>
            <p><strong>Total :</strong> {selectedCommande.total_price} €</p>
            <p><strong>Statut :</strong> {selectedCommande.status}</p>
            <p><strong>Articles :</strong></p>
            <ul>
              {selectedCommande.items.map((item, index) => (
                <li key={index}>{item.title} - Quantité : {item.quantity} - Prix : {item.price}€</li>
              ))}
            </ul>
            <button onClick={closeDetailModal}>Fermer</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminCommande;
