import { useState, useContext, useEffect } from 'react';
import '../scss/ConfirmCommande.scss';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

const ConfirmCommande = ({ userId }) => {
  const { user, cart, setCart, setUser } = useContext(AppContext); // Récupération de l'utilisateur et du panier dans le contexte
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [adresse, setAdresse] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [ville, setVille] = useState('');
  const [pays, setPays] = useState('France'); // Pays par défaut
  const [email, setEmail] = useState(''); // Champ pour l'email
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [passWithoutSignup, setPassWithoutSignup] = useState(false);
  const [error, setError] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  
  const navigate = useNavigate();
  const orderData = JSON.parse(localStorage.getItem('orderData')) || {}; // Assurez-vous que l'objet `orderData` existe

  console.log('userId:', userId);

  // Fonction pour récupérer le token depuis localStorage ou sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Fonction pour récupérer les informations utilisateur depuis l'API en fonction du userId
  const fetchUserData = async () => {
    try {
      const token = getToken();
      if (!token) throw new Error('Token non trouvé, veuillez vous reconnecter.');

      const response = await axios.get(`https://npm06.com/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        const data = response.data;
        // Mise à jour du contexte utilisateur avec les informations complètes
        setUser(data);

        // Préremplir les champs avec les informations récupérées
        setNom(data.username || '');
        setPrenom(data.usersurname || '');
        setAdresse(data.adresse || '');
        setCodePostal(data.code_postal || '');
        setVille(data.ville || '');
        setPays(data.pays || 'France');
        setEmail(data.email || '');
      } else {
        throw new Error('Impossible de récupérer les données utilisateur.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations utilisateur:', error);
      setError('Impossible de récupérer les informations utilisateur. Veuillez réessayer.');
    }
  };

  // Préremplir le formulaire si l'utilisateur est connecté
  useEffect(() => {
    if (userId) {
      fetchUserData(); // Récupérer les informations utilisateur si le userId est fourni
    }
  }, [userId]);

  // Gestion de la confirmation de la commande
  const handleConfirmOrder = async () => {
    // Vérification des conditions générales de vente
    if (!isConfirmed) {
      setError('Vous devez accepter les conditions pour confirmer la commande.');
      return;
    }
  
    // Validation des champs requis pour la commande
    if (!nom || !prenom || !adresse || !codePostal || !ville || !pays || !email) {
      setError('Tous les champs doivent être remplis.');
      return;
    }
  
    // Récupérer le token de session
    const token = getToken();
  
    // Calcul du prix total TTC de la commande
    const totalPriceTTC = calculateTotalPrice();
    const deliveryPrice = 29.0; // Frais de livraison fixe
  
    // Filtrer les articles provenant de CustomSongService dans le panier
    const customSongs = cart.filter(item => item.type === 'custom_song');
  
    // Construire les données de la commande (sans les chansons personnalisées pour l'instant)
    const orderData = {
      user_id: user?.user_id,
      items: cart.filter(item => item.type !== 'custom_song'), // Exclure les chansons personnalisées ici
      total_price: totalPriceTTC,
      delivery_price: deliveryPrice,
      total_ttc: totalPriceTTC + deliveryPrice,
      status: 'pending',
    };
  
    try {
      // **Étape 1 : Enregistrement de la commande dans la table orders**
      const responseOrder = await axios.post(`https://npm06.com/api/orders`, orderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Si la commande est enregistrée avec succès, on continue avec l'ajout des chansons personnalisées
      if (responseOrder.status === 201) {
        const orderId = responseOrder.data.order_id; // ID de la commande retournée
        // Exemple d'utilisation : enregistrer l'orderId dans le localStorage
localStorage.setItem('orderId', orderId);
  
        // **Étape 2 : Enregistrement des chansons personnalisées dans la table songs**
        for (const song of customSongs) {
          const songData = {
            user_id: user?.user_id,
            title: song.title,
            music_style: song.music_style,
            custom_text: song.custom_text || null,
            song_wish: song.song_wish || null,
            include_clip: song.include_clip ? 1 : 0,
            clip_option: song.clip_option || null,
            price: song.price,
            uploaded_files: song.files.length ? JSON.stringify(song.files) : null, // Si des fichiers sont inclus
            created_at: new Date().toISOString(), // Date de création
            updated_at: new Date().toISOString(), // Date de mise à jour
          };
          console.log('Enregistrement de la chanson:', songData);
  
          // Envoyer chaque chanson à l'API pour être enregistrée dans la table songs
          await axios.post('https://npm06.com/api/song', songData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
  
        // **Étape 3 : Vider le panier et rediriger vers la page de paiement**
        setCart([]); // Vider le panier après la commande
        localStorage.removeItem('orderData'); // Supprimer les données de commande du localStorage
        navigate('/payment'); // Rediriger vers la page de paiement
      }
    } catch (err) {
      console.error('Erreur lors de la validation de la commande:', err);
      setError('Erreur lors de la validation de la commande. Veuillez réessayer.');
    }
  };
  
  // Gestion de la suppression d'un article
  const handleRemoveItem = (id) => {
    setCart(cart.filter(item => item.id !== id));
  };

  // Augmenter la quantité d'un article
  const handleIncreaseQuantity = (id) => {
    setCart(cart.map(item =>
      item.id === id ? { ...item, quantity: (item.quantity || 1) + 1 } : item
    ));
  };

  // Diminuer la quantité d'un article
  const handleDecreaseQuantity = (id) => {
    setCart(cart.map(item =>
      item.id === id && (item.quantity || 1) > 1 ? { ...item, quantity: (item.quantity || 1) - 1 } : item
    ));
  };

  // Calculer le prix total de la commande
  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => {
      const quantity = item.quantity || 1; // Quantité par défaut à 1 si non spécifiée
      return total + parseFloat(item.price) * quantity;
    }, 0).toFixed(2);
  };

  return (
    <div className="confirm-commande-page">
      <div className="content-wrapper">
        {/* Première box : Récapitulatif de commande */}
        <div className="recap-section">
          <h2>Récapitulatif de votre commande</h2>
          <div className="cart-items">
            {cart.map((item, index) => (
              <div key={index} className="cart-item">
                <img
                  src={item.image_url ? `https://npm06.com/uploads/${item.image_url}` : '/images/fallback-image.jpg'}
                  alt={item.name}
                  className="item-image"
                  onError={(e) => { e.target.src = '/images/fallback-image.jpg'; }}
                />
                <div className="item-details">
                  <p className="item-name">{item.name}</p>
                  <p className="item-description">{item.description}</p> {/* Description du produit */}
                  <p className="item-price">{parseFloat(item.price).toFixed(2)} €</p>
                  <div className="item-quantity">
                    <button onClick={() => handleDecreaseQuantity(item.id)}>-</button>
                    <input type="number" value={item.quantity} readOnly className="quantity-input" />
                    <button onClick={() => handleIncreaseQuantity(item.id)}>+</button>
                  </div>
                  <button className="remove-button" onClick={() => handleRemoveItem(item.id)}>Supprimer</button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Seconde box : Récapitulatif prix */}
        <div className="cart-summary-widget">
          <h2>Récapitulatif de votre commande</h2>
          <input type="text" placeholder="Saisissez le code ici" className="discount-input" />
          <button className="apply-code">APPLIQUER</button>

          <div className="order-summary">
            <p>Total de ma commande: <span>{calculateTotalPrice()} €</span></p>
            <p>Pays de livraison:</p>
            <select value={pays} onChange={(e) => setPays(e.target.value)}>
              <option value="Belgium">Belgique</option>
              <option value="France">France</option>
              <option value="Germany">Allemagne</option>
            </select>
            <p>Frais de port: <span>{parseFloat(orderData.delivery_price || 0).toFixed(2)} €</span></p>
            <h3>Total TTC: <span>{(parseFloat(calculateTotalPrice()) + parseFloat(orderData.delivery_price || 0)).toFixed(2)} €</span></h3>
          </div>
        </div>

        {/* Troisième box : Formulaire et connexion */}
        <div className="form-section">
          <div className="auth-section">
            <h3>Déjà inscrit ?</h3>
            <button className="account-link" onClick={() => setShowLoginModal(true)}>Se connecter</button>

            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={passWithoutSignup}
                  onChange={() => setPassWithoutSignup(!passWithoutSignup)}
                />
                Passer la commande sans s'inscrire
              </label>
            </div>
          </div>

          <form className="confirm-form">
            <div className="form-group">
              <input
                type="text"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
                placeholder="Nom"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
                placeholder="Prénom"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
                required
                placeholder="Adresse"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={codePostal}
                onChange={(e) => setCodePostal(e.target.value)}
                required
                placeholder="Code Postal"
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
                required
                placeholder="Ville"
              />
            </div>
            <div className="form-group">
              <label>
                <input type="checkbox" checked={isConfirmed} onChange={() => setIsConfirmed(!isConfirmed)} />
                J'accepte les conditions générales de vente
              </label>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button type="button" onClick={handleConfirmOrder}>Confirmer la commande</button>
          </form>
        </div>
      </div>
    </div>
  );
};

ConfirmCommande.propTypes = {
  userId: PropTypes.number.isRequired, // Déclarez que `userId` est un nombre requis
};

export default ConfirmCommande;
