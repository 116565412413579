import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../widgets/SearchBar'; // Importation du composant SearchBar
import '../AdminScss/UserManager.scss';

const UserManager = () => {
  const [users, setUsers] = useState([]);   // Liste des utilisateurs
  const [formData, setFormData] = useState({  // Données du formulaire
    username: '',
    usersurname: '',
    email: '',
    password: '',
    adresse: '',
    code_postal: '',
    ville: '',
    pays: '',
    number_phone: '',
    role: 'user',
  });
  const [selectedUser, setSelectedUser] = useState(null); // Utilisateur sélectionné pour modification
  const [isModalOpen, setIsModalOpen] = useState(false);  // Etat du modal
  const [errorMessage, setErrorMessage] = useState('');    // Message d'erreur
  const navigate = useNavigate();

  // Fonction pour récupérer le token depuis localStorage ou sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Utilisation de useEffect pour récupérer les utilisateurs au chargement de la page
  useEffect(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    const parsedUser = storedUser ? JSON.parse(storedUser) : null;
    const token = getToken();

    // Vérification du rôle admin
    if (!parsedUser || parsedUser.role !== 'admin' || !token) {
      setErrorMessage("Accès refusé. Vous devez être administrateur pour accéder à cette page.");
      navigate('/login');
    } else {
      fetchUsers(token); // On appelle fetchUsers avec le token récupéré
    }
  }, [navigate]);

  // Fonction pour récupérer la liste des utilisateurs depuis l'API
  const fetchUsers = async (token) => {
    try {
      const response = await fetch('https://npm06.com/api/users', {
        headers: {
          Authorization: `Bearer ${token}`, // Ajout du token dans les headers
        },
      });
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des utilisateurs');
      }
      const data = await response.json();
      setUsers(data);  // Mise à jour des utilisateurs
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  };

  // Gestion des champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Ajout ou mise à jour d'un utilisateur
  const handleSave = async (e) => {
    e.preventDefault();
    const method = selectedUser ? 'PUT' : 'POST';  // PUT pour mise à jour, POST pour création
    const url = selectedUser
      ? `https://npm06.com/api/users/${selectedUser.user_id}`
      : `https://localhost:3000/api/users`;

    try {
      const token = getToken();
      const bodyData = { ...formData };
      if (selectedUser && !formData.password) {
        delete bodyData.password;  // Retirer le mot de passe s'il n'est pas modifié
      }

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Ajout du token dans les headers
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'enregistrement de l\'utilisateur');
      }

      // Actualiser la liste des utilisateurs après ajout/mise à jour
      fetchUsers(token);
      closeModal();  // Fermer le modal après succès
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de l\'utilisateur:', error);
    }
  };

  // Suppression d'un utilisateur
  const handleDeleteUser = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) return;

    try {
      const token = getToken();
      const response = await fetch(`https://npm06.com/api/users/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`, // Ajout du token dans les headers
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression de l\'utilisateur');
      }

      // Actualiser la liste des utilisateurs après suppression
      fetchUsers(token);  // Rafraîchir la liste
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'utilisateur:', error);
    }
  };

  // Ouvrir le modal avec les informations d'un utilisateur à modifier
  const openModal = (user = null) => {
    setSelectedUser(user);
    setFormData({
      username: user?.username || '',
      usersurname: user?.usersurname || '',
      email: user?.email || '',
      password: '',
      adresse: user?.adresse || '',
      code_postal: user?.code_postal || '',
      ville: user?.ville || '',
      pays: user?.pays || '',
      number_phone: user?.number_phone || '',
      role: user?.role || 'user',
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setFormData({
      username: '',
      usersurname: '',
      email: '',
      password: '',
      adresse: '',
      code_postal: '',
      ville: '',
      pays: '',
      number_phone: '',
      role: 'user',
    });
    setIsModalOpen(false);
  };

  return (
    <div className="user-manager-container">
      <h1>Gestion des utilisateurs</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <SearchBar
        data={users}
        onSearch={(filtered) => setUsers(filtered)}  // Mettre à jour les utilisateurs filtrés
        searchFields={['username', 'usersurname', 'email']}
      />
      <button className="add-user-button" onClick={() => openModal()}>
        Ajouter un utilisateur
      </button>
      <table className="user-table">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Email</th>
            <th>Téléphone</th>
            <th>Adresse</th>
            <th>Rôle</th>
            <th>Date de création</th>
            <th>Date de mise à jour</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            users.map((user) => (
              <tr key={user.user_id}>
                <td>{user.username} {user.usersurname}</td>
                <td>{user.email}</td>
                <td>{user.number_phone}</td>
                <td>{`${user.adresse}, ${user.code_postal}, ${user.ville}, ${user.pays}`}</td>
                <td>{user.role}</td>
                <td>{new Date(user.created_at).toLocaleDateString()}</td>
                <td>{new Date(user.updated_at).toLocaleDateString()}</td>
                <td>
                  <button onClick={() => openModal(user)}>Modifier</button>
                  <button onClick={() => handleDeleteUser(user.user_id)}>Supprimer</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">Aucun utilisateur trouvé</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSave}>
              <input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                placeholder="Prénom"
                required
              />
              <input
                type="text"
                name="usersurname"
                value={formData.usersurname}
                onChange={handleInputChange}
                placeholder="Nom"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email"
                required
              />
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Mot de passe"
                required={!selectedUser} // Mot de passe obligatoire uniquement pour les nouveaux utilisateurs
              />
              <input
                type="text"
                name="adresse"
                value={formData.adresse}
                onChange={handleInputChange}
                placeholder="Adresse"
                required
              />
              <input
                type="text"
                name="code_postal"
                value={formData.code_postal}
                onChange={handleInputChange}
                placeholder="Code postal"
                required
              />
              <input
                type="text"
                name="ville"
                value={formData.ville}
                onChange={handleInputChange}
                placeholder="Ville"
                required
              />
              <input
                type="text"
                name="pays"
                value={formData.pays}
                onChange={handleInputChange}
                placeholder="Pays"
                required
              />
              <input
                type="text"
                name="number_phone"
                value={formData.number_phone}
                onChange={handleInputChange}
                placeholder="Téléphone"
                required
              />
              <select
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="user">Utilisateur</option>
                <option value="admin">Administrateur</option>
              </select>
              <button type="submit">
                {selectedUser ? 'Mettre à jour' : 'Ajouter'}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManager;
