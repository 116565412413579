import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../scss/LoginPopup.scss';

import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import des icônes pour l'œil

const Login = ({ onLoginSuccess, isOpen, onClose, returnUrl }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [savePassword, setSavePassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // État pour gérer l'affichage du mot de passe
  const [isLoading, setIsLoading] = useState(false); // Nouveau state pour le chargement
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value.trim());
    if (name === 'password') setPassword(value.trim());
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true); // Désactive le bouton pendant le chargement

    try {
      const response = await fetch(`https://npm06.com/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('Identifiants incorrects. Veuillez réessayer.');
        } else if (response.status === 500) {
          throw new Error("Erreur du serveur. Veuillez réessayer plus tard.");
        } else {
          throw new Error('Erreur lors de la connexion. Veuillez réessayer.');
        }
      }

      const data = await response.json();

      // Récupération de l'utilisateur actuel dans le localStorage
      const existingUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

      // Comparaison entre l'utilisateur actuel et celui retourné par la connexion
      if (!existingUser || existingUser.email !== data.user.email) {
        // Si l'utilisateur est différent ou n'existe pas, on remplace les données
        const storage = rememberMe ? localStorage : sessionStorage; // Stockage en fonction de rememberMe
        storage.setItem('sessionToken', data.token);
        storage.setItem('user', JSON.stringify(data.user));

        // Sauvegarde du mot de passe si nécessaire
        if (savePassword) {
          localStorage.setItem('savedPassword', password); // Toujours dans localStorage pour garder le mot de passe
        }
      }

      // Succès de la connexion
      onLoginSuccess(data.user);
      setIsSuccess(true);
      setModalMessage('Connexion réussie ! Vous êtes maintenant connecté.');
      setShowModal(true);

      setTimeout(() => {
        setShowModal(false);
        onClose();
        // Redirection après connexion
        if (returnUrl) {
          navigate(returnUrl); 
        } else {
          navigate('/'); // Redirige vers l'accueil
        }
      }, 2000);
    } catch (error) {
      console.error('Erreur lors de la connexion:', error.message);
      setIsSuccess(false);
      setModalMessage(error.message);
      setShowModal(true);
    } finally {
      setIsLoading(false); // Remet isLoading à false après la requête
    }
  };

  // Fonction pour basculer l'affichage du mot de passe
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close-button" onClick={onClose}>&times;</span>
            <h1>Connexion</h1>
            <p>Connectez-vous à votre compte pour continuer.</p>

            {errors.form && <p className="error-message">{errors.form}</p>}

            <form className="login-form" onSubmit={handleLogin}>
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={handleChange}
                  required
                  autoComplete="username"
                />
              </div>

              <div className="form-group password-input-wrapper">
                <input
                  type={showPassword ? 'text' : 'password'} // Basculer entre texte et mot de passe
                  id="password"
                  name="password"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={handleChange}
                  required
                  autoComplete="current-password"
                />
                {/* Icône pour afficher/masquer le mot de passe */}
                <span className="toggle-password" onClick={toggleShowPassword}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>

              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  Se souvenir de moi
                </label>
              </div>

              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={savePassword}
                    onChange={(e) => setSavePassword(e.target.checked)}
                  />
                  Sauvegarder le mot de passe
                </label>
              </div>

              <button type="submit" className="submit-button" disabled={isLoading}>
                {isLoading ? 'Connexion en cours...' : 'Se connecter'}
              </button>
              <Link to="/signup" className="signup-link">S'inscrire</Link>
            </form>
          </div>
        </div>
      )}

      {showModal && (
        <div className="popup-modal">
          <div className={`popup-content ${isSuccess ? 'success' : 'error'}`}>
            <p>{modalMessage}</p>
          </div>
        </div>
      )}
    </>
  );
};

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  returnUrl: PropTypes.string, // URL de retour pour la redirection
};

export default Login;
