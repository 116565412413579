import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal'; // Importation de Modal
import '../scss/YouTubeVideos.scss';

// Fonction pour obtenir l'URL d'intégration YouTube
const getYouTubeEmbedUrl = (video_key) => {
  return `https://www.youtube.com/embed/${video_key}`;
};

const YouTubeVideos = () => {
  const [videos, setVideos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Gestion de l'état de la modale
  const [selectedVideoKey, setSelectedVideoKey] = useState(null); // Stocke la vidéo actuellement sélectionnée

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await fetch('https://npm06.com/api/youtubevideos');
        const data = await response.json();
  
        // Extraction du tableau de vidéos depuis l'objet réponse
        if (data && Array.isArray(data.videos)) {
          setVideos(data.videos); // Stocker uniquement la liste des vidéos
        } else {
          console.error('Les données vidéos ne sont pas disponibles ou ne sont pas un tableau:', data);
          setVideos([]); // Prévenir toute erreur en mettant un tableau vide
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des vidéos:', error);
        setVideos([]); // Valeur par défaut en cas d'erreur
      }
    };
    fetchVideos();
  }, []);
  
  

  // Fonction pour ouvrir la modale avec la vidéo sélectionnée
  const openModal = (video_key) => {
    setSelectedVideoKey(video_key); // Définit la vidéo sélectionnée
    setIsModalOpen(true); // Ouvre la modale
  };

  // Fonction pour fermer la modale
  const closeModal = () => {
    setIsModalOpen(false); // Ferme la modale
    setSelectedVideoKey(null); // Réinitialise la vidéo sélectionnée
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="videos-section">
      <h2>Vidéos YouTube</h2>
  
      {/* Carousel principal */}
      <div className="videos-carousel">
        <Slider {...settings}>
          {Array.isArray(videos) && videos.length > 0 ? (
            videos.map((video) => (
              <div key={video.video_id} className="video-card">
                <img
                  src={`https://img.youtube.com/vi/${video.video_key}/hqdefault.jpg`} // Miniature YouTube
                  alt={video.title}
                  onClick={() => openModal(video.video_key)} // Ouvre la modale avec la vidéo
                  style={{ cursor: 'pointer' }}
                />
                <h3>{video.title}</h3>
              </div>
            ))
          ) : (
            <p>Aucune vidéo disponible</p> // Message si aucune vidéo
          )}
        </Slider>
      </div>
  
      {/* Miniatures en dessous */}
      <div className="thumbnail-carousel">
        {Array.isArray(videos) && videos.length > 0 ? (
          videos.map((video) => (
            <div key={video.video_id} className="thumbnail-card">
              <img
                src={`https://img.youtube.com/vi/${video.video_key}/hqdefault.jpg`}
                alt={video.title}
                onClick={() => openModal(video.video_key)} // Ouvre la modale avec la vidéo
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))
        ) : (
          <p>Aucune miniature disponible</p>
        )}
      </div>
  
      {/* Modale pour afficher la vidéo YouTube */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Lecture de la vidéo YouTube"
        className="youtube-modal"
        overlayClassName="youtube-modal-overlay"
      >
        {selectedVideoKey && (
          <iframe
            width="560"
            height="315"
            src={getYouTubeEmbedUrl(selectedVideoKey)} // URL d'intégration de la vidéo
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
        <button onClick={closeModal} className="close-modal">
          Fermer
        </button>
      </Modal>
    </div>
  );
  
  
};

export default YouTubeVideos;
