import PropTypes from 'prop-types';
import '../scss/Modal.scss'; // Assurez-vous de styliser la modale dans votre fichier SCSS

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-box"> {/* Boîte modale centrée */}
        <button className="modal-close-button" onClick={onClose}>&times;</button>
        <div className="modal-content">
          {children} {/* Contenu dynamique passé comme children */}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired, // children peut être tout contenu dynamique
};

export default Modal;
