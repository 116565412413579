// AppContext.js
import { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// Création du contexte global
export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState('ProductManager');
  const [isPrivacyPopupOpen, setPrivacyPopupOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [annonces, setAnnonces] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Gestion du panier
  const [cart, setCart] = useState(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });

  // Gestion de l'utilisateur
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
    return storedUser
      ? JSON.parse(storedUser)
      : null;
  });

  const isLoggedIn = !!user && !!user.user_id;

  // Sauvegarde automatique du panier dans le localStorage lorsqu'il est mis à jour
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Sauvegarde automatique de l'utilisateur dans le stockage approprié
  useEffect(() => {
    if (user && user.user_id) {
      const storage = user.rememberMe ? localStorage : sessionStorage;
      storage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
      sessionStorage.removeItem('user');
    }
  }, [user]);

  // Gérer la connexion de l'utilisateur
  const handleLoginSuccess = useCallback((userData) => {
    if (userData) {
      setUser(userData);
      const storage = userData.rememberMe ? localStorage : sessionStorage;
      storage.setItem('user', JSON.stringify(userData));
    } else {
      console.error('Erreur: les données utilisateur sont invalides.');
    }
  }, []);

  // Gérer la déconnexion de l'utilisateur
  const handleLogout = useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
  }, []);

  // Ajouter un produit au panier
  const addToCart = (product) => {
    try {
      setCart((prevCart) => {
        const existingProduct = prevCart.find((item) => item.product_id === product.product_id);
        if (existingProduct) {
          return prevCart.map((item) =>
            item.product_id === product.product_id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          return [...prevCart, { ...product, quantity: 1 }];
        }
      });
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit au panier:", error);
      setError("Erreur lors de l'ajout du produit.");
    }
  };

  // Supprimer un produit du panier
  const removeFromCart = (productId) => {
    try {
      setCart((prevCart) => prevCart.filter((item) => item.product_id !== productId));
    } catch (error) {
      console.error("Erreur lors de la suppression du produit du panier:", error);
      setError("Erreur lors de la suppression du produit.");
    }
  };

  // Charger les produits depuis une API
  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = user?.token;
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.get(`https://npm06.com/api/products`, { headers });
      setProducts(response.data);
    } catch (error) {
      console.error("Erreur lors du chargement des produits:", error);
      setError("Impossible de charger les produits.");
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  // Charger les annonces depuis une API
  const fetchAnnonces = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = user?.token;
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      const response = await axios.get(`https://npm06.com/api/annonces`, { headers });
      setAnnonces(response.data);
    } catch (error) {
      console.error("Erreur lors du chargement des annonces:", error);
      setError("Impossible de charger les annonces.");
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  // Ajouter une annonce
  const addAnnonce = useCallback(
    async (formData) => {
      try {
        const token = user?.token;
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        };
        const response = await axios.post(`https://npm06.com/api/annonces`, formData, { headers });
        setAnnonces((prevAnnonces) => [...prevAnnonces, response.data]);
      } catch (error) {
        console.error("Erreur lors de l'ajout de l'annonce:", error);
        setError("Impossible d'ajouter l'annonce.");
      }
    },
    [user]
  );

  // Création du contexte partagé
  const contextValue = useMemo(
    () => ({
      annonces,
      fetchAnnonces,
      addAnnonce,
      activeComponent,
      setActiveComponent,
      isPrivacyPopupOpen,
      setPrivacyPopupOpen,
      products,
      fetchProducts,
      cart,
      addToCart,
      removeFromCart,
      user,
      setUser,
      isLoggedIn,
      handleLoginSuccess,
      handleLogout,
      error,
      isLoading,
    }),
    [
      annonces,
      fetchAnnonces,
      addAnnonce,
      activeComponent,
      isPrivacyPopupOpen,
      products,
      fetchProducts,
      cart,
      user,
      isLoggedIn,
      handleLoginSuccess,
      handleLogout,
      error,
      isLoading,
    ]
  );

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
