import { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../scss/ProductDetail.scss';
import { AppContext } from '../context/AppContext';

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useContext(AppContext);
  
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`https://npm06.com/api/products/${id}`);
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération du produit');
        }
        const data = await response.json();
        const productData = data.result;

        // S'assurer que additional_images est bien un tableau
        productData.additional_images = Array.isArray(productData.additional_images)
          ? productData.additional_images
          : [];

        setProduct(productData);

        // Définir l'image principale sélectionnée
        if (productData.image_url) {
          setSelectedImage(getImageUrl(productData.image_url));
        } else if (productData.additional_images.length > 0) {
          setSelectedImage(getImageUrl(productData.additional_images[0]));
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du produit:', error);
      }
    };

    fetchProduct();
  }, [id]);

    // Fonction pour récupérer l'URL d'une image
    const getImageUrl = (imagePath) => {
      return imagePath.startsWith('https') || imagePath.startsWith('/')
        ? imagePath
        : `https://npm06.com/uploads/${encodeURIComponent(imagePath)}`;
    };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const handleMouseMove = (e) => {
    const { top, left, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  if (!product) {
    return <div>Chargement...</div>;
  }

  const formattedPrice = product.price ? parseFloat(product.price).toFixed(2) : '0.00';

  const images = [
    product.image_url,
    ...product.additional_images,
  ].filter((image) => image && typeof image === 'string' && image.trim() !== '');

  return (
    <div className="product-detail-container">
      <div className="product-detail-left">
        <button onClick={() => navigate(-1)} className="back-button">
          ← Retour
        </button>
        {images.length > 0 && (
          <>
           <div
              className="main-image"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onMouseMove={handleMouseMove}
            >
              <img src={getImageUrl(selectedImage)} alt="Produit principal" />
              {isZoomed && (
                <div
                  className="zoom-popup"
                  style={{
                    backgroundImage: `url(${getImageUrl(selectedImage)})`,
                    backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
                  }}
                />
              )}
            </div>
            <div className="annonce-additional-images">
            {images.map((image, index) => (
                <div key={index} className="thumbnail-container">
                  <img
                    src={getImageUrl(image)}
                    alt={`Thumbnail ${index + 1}`}
                    className={`additional-image ${selectedImage === getImageUrl(image) ? 'selected' : ''}`}
                    onClick={() => setSelectedImage(getImageUrl(image))}
                  />
                </div>
  ))}
</div>
          </>
        )}
      </div>

      <div className="product-detail-right">
        <h1>{product.name}</h1>
        <div className="product-rating">
          <span>★★★★☆</span>
          <span>(5 avis)</span>
        </div>
        <p className="product-description">{product.description}</p>
      </div>

      <div className="product-detail-right2">
        <div className="product-price-section">
          <p className="product-price">{formattedPrice} €</p>
          <button onClick={() => addToCart(product)} className="add-to-cart-button">Ajouter au panier</button>
        </div>
        <div className="product-extra-info">
          <h3>Détails supplémentaires</h3>
          <ul>
            <li> {product.add_details || ''}</li>
          </ul>
        </div>
        <div className="product-shipping-info">
          <h3>Informations de livraison</h3>
          <p>Livraison gratuite en France métropolitaine.</p>
        </div>
      </div>
    </div>
  );
};

ProductDetail.propTypes = {
  addToCart: PropTypes.func.isRequired,
};

export default ProductDetail;
