import { useContext, useState, useEffect } from 'react';
import '../scss/CartSummary.scss';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const CartSummary = () => {
  const { cart, setCart, user, setUser } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('CartSummary component mounted');
    console.log('User from context:', user);
    console.log('Cart from context:', cart);
  }, [user, cart]);

  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  const handleSendOrderToOrderPage = () => {
    console.log('Bouton "Passer la commande" cliqué');
    const token = getToken();

    console.log('Utilisateur:', user);
    console.log('Token:', token);

    if (!token) {
      console.warn('Token manquant.');
      toast.error('Aucun token trouvé.');
    }

    setIsLoading(true);

    const orderData = {
      user_id: user?.user_id,
      total_price: cart.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0) + 4.0,
      delivery_price: 4.0,
      items: cart.map((item) => ({
        id: item.id,
        name: item.name || item.title,
        quantity: item.quantity || 1, // Par défaut, 1 si non défini
        price: parseFloat(item.price),
      })),
    };

    console.log('Données de la commande à envoyer à OrderPage:', orderData);

    navigate('/orderpage', { state: { orderData } });

    setIsLoading(false);
  };

  if (cart.length === 0) {
    return <p>Votre panier est vide</p>;
  } 

  return (
    <div className="cart-summary-page">
      <div className="cart-left">
        <h2>Mon Panier</h2>
        <div className="cart-items">
          {cart.map((item, index) => (
            <div key={`${item.id}-${index}`} className="cart-item">
              <div className="item-image">
                <img
                  src={item.image_url ? `/uploads/${item.image_url}` : '/images/no-pict.jpg'}
                  alt={item.name || item.title}
                  onError={(e) => {
                    e.target.src = '/images/fallback-image.jpg';
                  }}
                  style={{ maxWidth: '150px', maxHeight: '150px' }}
                />
              </div>
              <div className="item-details">
                <p className="item-name">{item.name || item.title}</p>
                <p className="item-price">
                  {item.offered ? 'Offert' : `${parseFloat(item.price).toFixed(2)} €`}
                </p>
                <button className="save-for-later">Sauvegarder pour plus tard</button>
                <button
                  className="remove-button"
                  onClick={() => setCart(cart.filter((cartItem) => cartItem.id !== item.id))}
                >
                  Supprimer
                </button>
              </div>
              <div className="item-quantity">
                <button
                  onClick={() =>
                    setCart(cart.map((cartItem) =>
                      cartItem.id === item.id
                        ? { ...cartItem, quantity: (cartItem.quantity || 1) - 1 }
                        : cartItem
                    ))
                  }
                >
                  -
                </button>
                <input type="number" value={item.quantity || 1} readOnly />
                <button
                  onClick={() =>
                    setCart(cart.map((cartItem) =>
                      cartItem.id === item.id
                        ? { ...cartItem, quantity: (cartItem.quantity || 1) + 1 }
                        : cartItem
                    ))
                  }
                >
                  +
                </button>
              </div>
              <span className="item-total">{parseFloat(item.price * (item.quantity || 1)).toFixed(2)} €</span>
            </div>
          ))}
        </div>
      </div>

      <div className="cart-right">
        <div className="cart-summary-widget">
          <div className="totals">
            <p>
              Sous-total ({cart.length} articles): {parseFloat(cart.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0)).toFixed(2)} €
            </p>
            <p>Livraison: 4.00 €</p>
            <h3>Total: {parseFloat(cart.reduce((total, item) => total + parseFloat(item.price) * (item.quantity || 1), 0) + 4.0).toFixed(2)} €</h3>
          </div>
          <button
            className="validate-button"
            onClick={handleSendOrderToOrderPage}
            disabled={isLoading}
          >
            {isLoading ? 'Commande en cours...' : 'Passer la commande'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartSummary;
