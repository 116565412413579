import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../assets/logo.png';
import '../scss/Header.scss';
import Login from './Login';
import CartPreview from './CartPreview';

const Header = ({ user, setUser }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCartPreview, setShowCartPreview] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();
  const isAdmin = user?.role === 'admin';

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    navigate('/');
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleClosePopup = () => {
    setShowLoginModal(false);
  };

  const handleCartMouseEnter = () => {
    setShowCartPreview(true);
  };

  const handleCartMouseLeave = () => {
    setShowCartPreview(false);
  };

  const handleNavigateDashboard = () => {
    if (user && isAdmin) {
      navigate('/admin/dashboardlayout');
    } else if (user && !isAdmin) {
      setShowLoginModal(true);
    }
  };

  return (
    <header className="header">
      <div className="top-bar">
        <Link to="/">
          <img src={logo} alt="NPM Run Dev Logo" className="logo" />
        </Link>
        <div className="nomsociete">NPM RUN DEV</div>
        <div className="search-bar">
          <input type="text" placeholder="Recherche..." className="search-input" />
          <button className="search-button">🔍</button>
        </div>
        <div className="account-cart">
          {!user ? (
            <button className="account-link" onClick={handleLoginClick}>
              Se connecter / S'inscrire
            </button>
          ) : (
            <div
              className="user-info"
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <span className="welcome-message">Bienvenue {user.username}</span>
              {showDropdown && (
                <div className="dropdown-menu">
                  <ul>
                    <li><Link to="/MonCompte">Mon Compte</Link></li>
                    <li><Link to="/MyOrders">Mes commandes</Link></li>
                    <li><Link to="/profile#profile">Mon profil</Link></li>
                    <li><Link to="/MessageUser">Ma messagerie</Link></li>
                    <li>
                      <button onClick={handleLogout} className="logout-button">Se déconnecter</button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          )}
          <div
            className="cart-container"
            onMouseEnter={handleCartMouseEnter}
            onMouseLeave={handleCartMouseLeave}
          >
            <Link to="/cart" className="cart-link">🛒 Panier</Link>
            {showCartPreview && <CartPreview />}
          </div>
        </div>
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <nav className={`nav-bar ${isOpen ? 'active' : ''}`}>
        <ul className="nav-links">
          <li><Link to="/ServiceOverview">Services</Link></li>
          <li><Link to="/Shopping">Boutique</Link></li>
          <li><Link to="/YouTubeVideos">Vidéos YouTube</Link></li>
          {isAdmin && (
            <>
              <li><button onClick={handleNavigateDashboard}>Dashboard</button></li>
              <li><button onClick={() => navigate('/CleanUploads')}>Nettoyage Upload</button></li>
            </>
          )}
          <li><Link to="/Annonce">Annonce</Link></li>
        </ul>
      </nav>

      {showLoginModal && (
        <Login
          isOpen={showLoginModal}
          onClose={handleClosePopup}
          onLoginSuccess={(userData) => {
            setUser(userData);
            setShowLoginModal(false);
            navigate('/');
          }}
        />
      )}
    </header>
  );
};

Header.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    role: PropTypes.string,
  }),
  setUser: PropTypes.func.isRequired,
};

export default Header;
