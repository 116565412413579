import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import '../scss/MessageUser.scss';

const MessageUser = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [reply, setReply] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();

  // Fonction pour récupérer le token depuis localStorage ou sessionStorage
  const getToken = () => {
    return localStorage.getItem('sessionToken') || sessionStorage.getItem('sessionToken');
  };

  // Fonction pour récupérer les messages
  useEffect(() => {
    const fetchMessages = async () => {
      const token = getToken(); // Récupérer le token
      if (!token) {
        setError('Utilisateur non authentifié');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`https://npm06.com/api/contactmessages/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data && response.data.data) {
          setMessages(response.data.data);
        } else {
          setMessages([]);
        }

        const savedMessageId = localStorage.getItem('selectedMessageId');
        if (savedMessageId) {
          const foundMessage = response.data.data.find(
            (msg) => msg.message_id === parseInt(savedMessageId, 10)
          );
          if (foundMessage) {
            setSelectedMessage(foundMessage);
          }
        }
      } catch (err) {
        setError('Erreur lors de la récupération des messages');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchMessages();
    }
  }, [userId]);

  // Fonction pour sélectionner un message
  const handleSelectMessage = useCallback((message) => {
    setSelectedMessage(message);
    localStorage.setItem('selectedMessageId', message.message_id);
  }, []);

  // Fonction pour changer la valeur de la réponse
  const handleReplyChange = useCallback((e) => {
    setReply(e.target.value);
  }, []);

  // Fonction pour envoyer une réponse
  const handleSendReply = useCallback(async () => {
    setSending(true); // Commence le chargement
    const token = getToken(); // Récupérer le token pour l'envoi de la réponse
    if (!token) {
      setError('Utilisateur non authentifié');
      setSending(false);
      return;
    }

    if (selectedMessage && reply.trim() !== '') {
      try {
        // URL pour envoyer la réponse
        const response = await axios.post(
          `https://npm06.com/api/contactmessages/${selectedMessage.message_id}/response`,
          {
            response_text: reply.trim(),
            response_user_id: userId, // Utilisateur qui envoie la réponse
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        if (response.status === 200) {
          const updatedMessage = response.data.contactMessage;

          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.message_id === selectedMessage.message_id
                ? updatedMessage
                : msg
            )
          );

          setSelectedMessage(updatedMessage);
          setReply(''); // Réinitialiser la réponse après envoi
        } else {
          setError("Erreur lors de l'envoi de la réponse");
        }
      } catch (err) {
        setError("Erreur lors de l'envoi de la réponse");
      } finally {
        setSending(false); // Arrête le chargement
      }
    }
  }, [selectedMessage, reply, userId]);

  const handleNewMessage = useCallback(() => {
    navigate('/contact');
  }, [navigate]);

  if (loading) {
    return <div>Chargement des messages...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="message-user">
      <div className="message-box">
        <div className="sidebar">
          <div className="sidebar-header">
            <h2>Conversations</h2>
            <button onClick={handleNewMessage} className="new-message-button">
              Nouveau Message
            </button>
          </div>
          <ul className="message-list">
            {messages.length > 0 ? (
              messages.map((message) => (
                <li
                  key={message.message_id}
                  className={`subject-item ${
                    selectedMessage?.message_id === message.message_id ? 'active' : ''
                  }`}
                  onClick={() => handleSelectMessage(message)}
                >
                  <h3>{message.subject || "Pas de sujet"}</h3>
                  <p className="message-preview">
                    {message.message_text
                      ? message.message_text.length > 30
                        ? `${message.message_text.substring(0, 30)}...`
                        : message.message_text
                      : "Pas de message"}
                  </p>
                </li>
              ))
            ) : (
              <li className="no-messages">Aucun message trouvé.</li>
            )}
          </ul>
        </div>

        <div className="chat-container">
          {selectedMessage ? (
            <>
              <div className="chat-header">
                <h2>{selectedMessage.subject || "Pas de sujet"}</h2>
              </div>
              <div className="chat-messages">
                <div className="message from-user">
                  <div className="message-info">
                    <span className="sender">Vous</span>
                    <span className="timestamp">
                      {new Date(selectedMessage.created_at).toLocaleString('fr-FR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })}
                    </span>
                  </div>
                  <p>{selectedMessage.message_text || "Pas de message"}</p>
                </div>
                {selectedMessage.responses && selectedMessage.responses.length > 0 && (
                  selectedMessage.responses.map((response, index) => (
                    <div key={index} className="message from-support">
                      <div className="message-info">
                        <span className="sender">Support</span>
                        <span className="timestamp">
                          {new Date(response.response_created_at).toLocaleString('fr-FR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                          })}
                        </span>
                      </div>
                      <p>{response.response_text}</p>
                    </div>
                  ))
                )}
              </div>
              <div className="chat-input">
                <textarea
                  value={reply}
                  onChange={handleReplyChange}
                  placeholder="Votre réponse..."
                  rows={3}
                />
                <button onClick={handleSendReply} disabled={reply.trim() === '' || sending}>
                  {sending ? 'Envoi en cours...' : 'Envoyer'}
                </button>
              </div>
            </>
          ) : (
            <div className="welcome-message">
              <h2>Bienvenue dans votre messagerie</h2>
              <p>Sélectionnez une conversation ou créez un nouveau message pour commencer.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MessageUser.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default MessageUser;
