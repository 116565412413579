import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal'; // Importation de react-modal pour les popups
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import '../scss/Slider.scss';
// import config from '../config/config';
// import config2 from '../config/config2'; // Utilisez config2 pour les images en HTTP

// Configuration du modal
Modal.setAppElement('#root');

const SliderComponent = () => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [images, setImages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentVideoUrl, setCurrentVideoUrl] = useState('');
    const [error, setError] = useState(null); // Ajout d'un état pour gérer les erreurs

  // Fetch images from the API
useEffect(() => {
    fetch(`https://npm06.com/api/sliderimages`, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        const processedImages = data.map(image => ({
            ...image,
            image_url: image.image_url.startsWith('https') 
              ? image.image_url 
              : `https://npm06.com/uploads/${image.image_url}` 
        }));
        setImages(processedImages);
    })
    .catch(error => {
        console.error('Erreur lors de la récupération des images:', error);
        setError('Erreur lors du chargement des images.'); // Gérer l'erreur
    });
}, []); 


    // Settings for the main slider
    const mainSettings = {
        asNavFor: nav2,
        ref: (slider) => setNav1(slider),
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
    };

    // Settings for the thumbnail slider
    const thumbSettings = {
        asNavFor: nav1,
        ref: (slider) => setNav2(slider),
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        centerMode: true,
        centerPadding: '0px',
        infinite: true,
    };

    // Fonction pour ouvrir la popup avec la vidéo YouTube
    const openModalWithVideo = (videoUrl) => {
        const videoId = videoUrl.split('v=')[1]; // Extraire l'ID de la vidéo
        setCurrentVideoUrl(videoId);
        setIsModalOpen(true);
    };

    // Fonction pour fermer le modal
    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentVideoUrl('');
    };

    return (
        <div className="slider-container">
            {/* Afficher un message d'erreur si les images ne peuvent pas être chargées */}
            {error && <div className="error-message">{error}</div>}

            {/* Main slider */}
            <Slider {...mainSettings} className="main-slider">
                {images.map((image, index) => (
                    <div key={index}>
                        {/* Si l'image est une vidéo YouTube, on affiche la miniature */}
                        {image.type === 'youtube' ? (
                            <div className="youtube-thumbnail" onClick={() => openModalWithVideo(image.video_url)}>
                                <img
                                    src={`https://img.youtube.com/vi/${image.video_id}/hqdefault.jpg`}
                                    alt={image.alt_text || `Video ${index + 1}`}
                                    className="slider-image"
                                />
                                <div className="play-button-overlay">▶️</div>
                            </div>
                        ) : (
                            <img
                                src={image.image_url}
                                alt={image.alt_text || `Slide ${index + 1}`}
                                className="slider-image"
                            />
                        )}
                    </div>
                ))}
            </Slider>

            {/* Thumbnail slider */}
            <Slider {...thumbSettings} className="slider-thumbs">
                {images.map((image, index) => (
                    <div key={index}>
                        <img
                            src={image.image_url}
                            alt={image.alt_text || `Thumbnail ${index + 1}`}
                            className="slider-thumb-image"
                        />
                    </div>
                ))}
            </Slider>

            {/* Modal pour la vidéo YouTube */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Vidéo YouTube"
                className="video-modal"
                overlayClassName="video-modal-overlay"
            >
                <div className="video-container">
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${currentVideoUrl}`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="YouTube video"
                    ></iframe>
                    <button onClick={closeModal} className="close-modal-button">Fermer</button>
                </div>
            </Modal>
        </div>
    );
}

export default SliderComponent;
