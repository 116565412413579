import '../AdminScss/Navigation.scss';
import '../AdminScss/AdminDashboard.scss';
import PropTypes from 'prop-types';
import { FaBox, FaBullhorn, FaUser, FaShoppingCart, FaEnvelope, FaFileAlt, FaImages, FaVideo, FaUsers } from 'react-icons/fa';

const Sidebar = ({ setActiveComponent }) => {
  return (
    <aside className="sidebar">
      <nav>
        <ul>
          {/* Gestion des produits et annonces */}
          <li>
            <button onClick={() => setActiveComponent('ProductManager')} aria-label="Gestion des Produits">
              <FaBox /> Gestion des Produits
            </button>
          </li>
          <li>
            <button onClick={() => setActiveComponent('AnnoncesManager')} aria-label="Gestion des Annonces">
              <FaBullhorn /> Gestion des Annonces
            </button>
          </li>

          {/* Gestion des services */}
          <li>
            <button onClick={() => setActiveComponent('services')} aria-label="Gestion des Services">
              <FaFileAlt /> Gestion des Services
            </button>
          </li>

          {/* Gestion des utilisateurs */}
          <li>
            <button onClick={() => setActiveComponent('UserManager')} aria-label="Gestion des Utilisateurs">
              <FaUser /> Gestion des Utilisateurs
            </button>
          </li>

          {/* Gestion des commandes */}
          <li>
            <button onClick={() => setActiveComponent('AdminCommande')} aria-label="Gestion des Commandes">
              <FaShoppingCart /> Gestion des Commandes
            </button>
          </li>

          {/* Messages de contact */}
          <li>
            <button onClick={() => setActiveComponent('contactMessages')} aria-label="Messages de Contact">
              <FaEnvelope /> Messages de Contact
            </button>
          </li>

          {/* Gestion du contenu */}
          <li>
            <button onClick={() => setActiveComponent('pages')} aria-label="Gestion des Pages">
              <FaFileAlt /> Gestion des Pages
            </button>
          </li>
          <li>
            <button onClick={() => setActiveComponent('SongManager')} aria-label="Gestion song">
              <FaImages /> Gestion song
            </button>
          </li>
          <li>
            <button onClick={() => setActiveComponent('YouTubeManager')} aria-label="Gestion des Vidéos"> {/* Utilisation de YouTubeManager ici */}
              <FaVideo /> Édition Vidéo
            </button>
          </li>

          {/* Sessions utilisateur */}
          <li>
            <button onClick={() => setActiveComponent('sessions')} aria-label="Sessions Utilisateurs">
              <FaUsers /> Sessions Utilisateurs
            </button>
          </li>

          {/* SliderManager et Messages */}
          <li>
            <button onClick={() => setActiveComponent('SliderManager')} aria-label="SliderManager">
              <FaImages /> SliderManager
            </button>
          </li>
          <li>
            <button onClick={() => setActiveComponent('MessagesManager')} aria-label="Messages">
              <FaEnvelope /> Messages
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

Sidebar.propTypes = {
  setActiveComponent: PropTypes.func.isRequired,
};

export default Sidebar;
