import '../scss/Home.scss';

function Home() {
  return (
    <div>
  
   
    <div className="home-container">
      <header className="home-header">
        <h1>Bienvenue sur notre site</h1>
        <p>Découvrez nos derniers produits, services, et bien plus encore.</p>
      </header>
      
      <section className="home-features">
        <div className="feature">
          <img src="/path-to-your-image-1.jpg" alt="Feature 1" />
          <h3>Produits de qualité</h3>
          <p>Nous offrons une large gamme de produits sélectionnés avec soin.</p>
        </div>
        <div className="feature">
          <img src="/path-to-your-image-2.jpg" alt="Feature 2" />
          <h3>Services exceptionnels</h3>
          <p>Nos services sont conçus pour répondre à tous vos besoins.</p>
        </div>
        <div className="feature">
          <img src="/path-to-your-image-3.jpg" alt="Feature 3" />
          <h3>Support client</h3>
          <p>Notre équipe est là pour vous aider 24/7.</p>
        </div>
      </section>
      
      <section className="home-about">
        <h2>À propos de nous</h2>
        <p>Notre entreprise est dédiée à fournir le meilleur service possible.</p>
      </section>
      
      <footer className="home-footer">
        <p>&copy; 2024 Votre Entreprise. Tous droits réservés.</p>
      </footer>
      </div>
    </div>
  );

}

export default Home;
