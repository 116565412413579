import { useState, useEffect, useContext } from 'react';
import '../scss/Contact.scss';
import Login from './Login';
import { AppContext } from '../context/AppContext';

function ContactForm() {
  const { isLoggedIn, setIsLoggedIn } = useContext(AppContext);  // Utilisation du contexte global pour la connexion
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');  // Ajout de l'état pour le sujet
  const [message, setMessage] = useState('');
  const [followUp, setFollowUp] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // Pour savoir si le formulaire a été soumis

  // Vérifier si l'utilisateur est déjà connecté lors du chargement du composant
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    // Vérifier si l'utilisateur souhaite un suivi et s'il est connecté
    if (followUp && !isLoggedIn) {
      setShowLogin(true);  // Ouvrir la fenêtre de connexion si non connecté
      setFormSubmitted(true);  // Marquer que le formulaire a été soumis
      return;
    }

    const formData = { name, email, phone, subject, message, followUp };  // Inclure le sujet dans les données envoyées

    try {
      const response = await fetch(`https://npm06.com/api/contactmessages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi du formulaire.');
      }

      // Sauvegarder une copie de la réponse dans la table "messageresponses" pour le suivi
      const messageData = await response.json();
      if (followUp && isLoggedIn) {
        const response = await fetch(`https://npm06.com/api/contactmessages/responses`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('userToken')}`, // Inclure le token de l'utilisateur
          },
          body: JSON.stringify({
            message_id: messageData.message_id, // ID du message enregistré
            response: "Votre message a été reçu, nous vous répondrons bientôt.",
          }),
        });
      }

      setSuccess(true);  // Message envoyé avec succès
      setFormSubmitted(false);  // Réinitialiser l'état de soumission du formulaire
    } catch (error) {
      setError(error.message);
    }
  };

  // Gérer la connexion réussie et soumettre le message
  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    setShowLogin(false);  // Fermer le popup de connexion
    if (formSubmitted) {
      handleSubmit(new Event('submit'));  // Soumettre le formulaire après la connexion
    }
  };

  // Gérer la fermeture du popup de connexion
  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <div className="contact-info">
          <h2>Adresse</h2>
          <p>251 avenue de la porte des Alpes</p>
          <p>06670 Levens</p>
          <h2>Parlons-en</h2>
          <p>+33 645424233</p>
          <h2>Support général</h2>
          <p>contact@npm06.fr</p>
        </div>

        <div className="contact-form">
          <h1>Votre Message</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Votre nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Eg. example@email.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label>Téléphone</label>
              <input
                type="text"
                placeholder="Eg. +1 800 000000"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Sujet</label>  {/* Ajout du champ sujet */}
              <input
                type="text"
                placeholder="Objet de votre message"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label>Message</label>
              <textarea
                placeholder="Votre message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <div className="form-group follow-up">
              <label>
                <input
                  type="checkbox"
                  checked={followUp}
                  onChange={() => setFollowUp(!followUp)}
                />
                Je souhaite recevoir un suivi
              </label>
            </div>

            <button type="submit" className="submit-button">Envoyer</button>

            {error && <p className="error">{error}</p>}
            {success && <p className="success">Message envoyé avec succès !</p>}
          </form>
        </div>
      </div>

      {showLogin && (
        <Login
          isOpen={showLogin}
          onClose={handleCloseLogin}
          onLoginSuccess={handleLoginSuccess}
          returnUrl="/contact"  // Redirection vers la page de contact après connexion
        />
      )}
    </div>
  );
}

export default ContactForm;
