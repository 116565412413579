import { useState, useContext, useEffect } from 'react';
import '../scss/OrderPage.scss';
import { AppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const OrderPage = () => {
  const { cart, setCart, user } = useContext(AppContext); // Cart et user du contexte
  const [deliveryCountry, setDeliveryCountry] = useState('Belgium');
  const deliveryPrice = 29.0; // Frais de livraison fixes
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Suppression de la vérification de l'authentification
  useEffect(() => {
    const token = localStorage.getItem('sessionToken');
    if (!token) {
      console.warn("Aucun token trouvé, mais l'utilisateur est toujours autorisé à accéder à la page.");
    }
  }, []);

  // Fonction pour formater le prix TTC
  const formatPrice = (price) => {
    const numericPrice = parseFloat(price); // Convertir en nombre
    return isNaN(numericPrice) ? '0.00€' : numericPrice.toFixed(2) + '€'; // Vérifier si c'est bien un nombre
  };

  // Calcul du prix total TTC du panier
  const totalPriceTTC = cart.reduce((total, item) => {
    const itemPrice = parseFloat(item.price) || 0; // S'assurer que le prix est un nombre
    const quantity = item.quantity || 1; // Par défaut, 1 si non défini
    return total + itemPrice * quantity;
  }, 0);

  // Supprimer un article du panier
  const handleRemoveItem = (id) => {
    setCart(cart.filter(item => item.id !== id)); // Retirer l'article du panier
  };

  // Fonction pour préparer la commande et la sauvegarder dans le localStorage
  const handlePrepareOrder = () => {
    if (cart.length === 0) {
      setError('Votre panier est vide.');
      return;
    }

    const orderData = {
      user_id: user?.user_id,
      items: cart,
      total_price: totalPriceTTC,
      delivery_price: deliveryPrice,
      total_ttc: totalPriceTTC + deliveryPrice,
      status: 'pending',
    };

    // Sauvegarde de la commande dans le localStorage
    localStorage.setItem('orderData', JSON.stringify(orderData));

    // Redirection vers la page ConfirmCommande
    navigate('/confirmcommande');
  };

  return (
    <div className="order-page">
      <div className="order-container">
        
        {/* Section Panier */}
        <div className="cart-section">
          <h2>VOTRE PANIER</h2>
          {cart.length > 0 ? (
            cart.map((item, index) => {
              const quantity = item.quantity || 1; // Par défaut, 1 si non défini
              return (
                <div key={`${item.id}-${index}`} className="cart-item">
                  <img
                    src={`https://npm06.com/uploads/${item.image_url}`} 
                    alt={item.title}
                    className="item-image"
                  />
                  <div className="cart-item-info">
                    <p className="item-title">{item.title}</p>
                    <button className="remove-item" onClick={() => handleRemoveItem(item.id)}>
                      Supprimer
                    </button>
                  </div>
                  <div className="cart-item-price">
                    <p>{formatPrice(item.price)} TTC</p>
                    <input
                      type="number"
                      value={quantity} // Par défaut, 1 si non défini
                      className="item-quantity"
                      readOnly
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <p>Votre panier est vide</p>
          )}
        </div>

        {/* Section Récapitulatif */}
        <div className="summary-section">
          <h2>Récapitulatif de votre commande</h2>
          <input type="text" placeholder="Saisissez le code ici" className="discount-input" />
          <button className="apply-code">APPLIQUER</button>
          
          <div className="order-summary">
            <p>Total de ma commande: <span>{formatPrice(totalPriceTTC)}</span></p>
            <p>
              Pays de livraison:
              <select value={deliveryCountry} onChange={(e) => setDeliveryCountry(e.target.value)}>
                <option value="Belgium">Belgique</option>
                <option value="France">France</option>
                <option value="Germany">Allemagne</option>
              </select>
            </p>
            <p>Frais de port: <span>{formatPrice(deliveryPrice)}</span></p>
            <h3>Total TTC: <span>{formatPrice(totalPriceTTC + deliveryPrice)}</span></h3>
          </div>
          
          {error && <p className="error-message">{error}</p>}
          <button className="place-order" onClick={handlePrepareOrder}>COMMANDER</button>
          
          <div className="payment-methods">
            <img src="/images/mastercard-logo.png" alt="Mastercard" />
            <img src="/images/visa-logo.png" alt="Visa" />
            <img src="/images/paypal-logo.png" alt="Paypal" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
