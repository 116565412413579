// DashboardLayout.jsx
import { useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext'; // Assurez-vous d'importer le contexte
import DashboardHeader from './DashboardHeader';
import DashboardFooter from './DashboardFooter';
import AdminDashboard from './AdminDashboard';
import AdminCommande from './AdminCommande';
import Sidebar from './Sidebar';
import ProductManager from './ProductManager';
import AnnoncesManager from './AnnoncesManager';
import MessagesManager from './MessagesManager';
import SliderManager from './SliderManager';
import UserManager from './UserManager';
import YouTubeManager from './YouTubeManager';
import SongManager from './SongManager';
import '../AdminScss/DashboardLayout.scss';

const DashboardLayout = () => {
  const [activeComponent, setActiveComponent] = useState('dashboardlayout');
  const { user } = useContext(AppContext); // Utilisation du contexte pour obtenir l'utilisateur

  console.log('DashboardLayout - User:', user); // Ajoutez ce log

  // Vérification si l'utilisateur est connecté
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Vérification du rôle administrateur
  const isAdmin = user.role === 'admin';

  if (!isAdmin) {
    return <Navigate to="/login" />;
  }

  // Fonction pour afficher le composant actif selon la sélection dans la sidebar
  const renderComponent = () => {
    switch (activeComponent) {
      case 'ProductManager':
        return <ProductManager />;
      case 'SliderManager':
        return <SliderManager />;
      case 'AnnoncesManager':
        return <AnnoncesManager />;
      case 'AdminCommande':
        return <AdminCommande />;
      case 'MessagesManager':
        return <MessagesManager />;
      case 'UserManager':
        return <UserManager />;
      case 'YouTubeManager':
        return <YouTubeManager />;
      case 'SongManager':
        return <SongManager />;
      default:
        return <AdminDashboard />;
    }
  };

  // Rendu final du layout de l'administration
  return (
    <main className="Dash">
      <DashboardHeader />
      <div className="dashboard-layout">
        <Sidebar setActiveComponent={setActiveComponent} />
        <div className="content-area">
          {renderComponent()}
        </div>
      </div>
      <DashboardFooter />
    </main>
  );
};

export default DashboardLayout;
