import '../scss/ServiceOverview.scss';
import { Link } from 'react-router-dom'; // Importez le composant Link

const ServiceOverview = () => (
  <section id="services" className="services">
    <h2>Nos Services</h2>
    <div className="service-cards">
    <div className="service-card">
        <h3>Webmaster Fullstack</h3>
        <p>Création et gestion de sites web, développement front-end et back-end.</p>
        <Link to="/webmaster-fullstack" className="service-link">En savoir plus</Link> {/* Lien vers la page Webmaster Fullstack */}
      </div>
      <div className="service-card">
        <h3>Chanson Personnalisée</h3>
        <p>Création de chansons personnalisées pour vos événements spéciaux.</p>
        <Link to="/CustomSongService" className="service-link">En savoir plus</Link> {/* Lien vers la page Webmaster Fullstack */}
      </div>
      <div className="service-card">
        <h3>Montage Vidéo</h3>
        <p>Montage professionnel de vidéos pour divers projets, avec effets spéciaux et animations.</p>
        <Link to="/VideoEditing" className="service-link">En savoir plus</Link> {/* Lien vers la page Webmaster Fullstack */}
      </div>
      
      <div className="service-card">
        <h3>Impression 3D</h3>
        <p>Description du service...</p>
      </div>
      <div className="service-card">
        <h3>Sublimation</h3>
        <p>Description du service...</p>
      </div>
      <div className="service-card">
        <h3>Événementiel</h3>
        <p>Description du service...</p>
      </div>
     
    </div>
  </section>
);

export default ServiceOverview;
